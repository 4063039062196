import Image from 'next/image';
import { Container, Section } from '@/components';
import { APP_NAME } from '@/constants';
import { getAssetPath } from '@/utils';
import { HeartIcon } from 'lucide-react';
import { THeroSection } from './hero-section.types';
export const HeroSection = ({
  children
}: THeroSection) => {
  return <Container className="mb-24" data-sentry-element="Container" data-sentry-component="HeroSection" data-sentry-source-file="hero-section.tsx">
      <div className="grid justify-between space-y-4 md:gap-8 md:space-y-0 lg:grid-cols-2">
        <div className="col-span-1 mb-4 space-y-6">
          <div className="flex items-center justify-center text-3xl font-extrabold tracking-tight text-gray-900 dark:text-slate-200 sm:text-5xl md:text-6xl">
            <h1 className="mx-auto block xl:inline">
              <span>Become a Social Media Star with</span>
              <span className="bg-gradient-to-tl from-purple-800 to-blue-500 bg-clip-text text-transparent">
                {` ${APP_NAME}`}
              </span>
            </h1>
          </div>

          <div className="mt-10 flex w-fit items-center gap-4">
            <span className="inline-flex items-center -space-x-4">
              {[1, 2, 3, 4, 5, 6].map(i => <Image key={i} width={56} height={56} className="border-1 h-10 w-10  rounded-full bg-gray-50 lg:h-14 lg:w-14" src={getAssetPath(`Avatar-${i}.jpg`)} alt={`Avatar ${i}`} />)}
            </span>
            <div>
              <div className="flex  items-center gap-1">
                <HeartIcon className="size-4 fill-red-500 text-red-500 lg:size-5" data-sentry-element="HeartIcon" data-sentry-source-file="hero-section.tsx" />
                <HeartIcon className="size-4 fill-red-500 text-red-500 lg:size-5" data-sentry-element="HeartIcon" data-sentry-source-file="hero-section.tsx" />
                <HeartIcon className="size-4 fill-red-500 text-red-500 lg:size-5" data-sentry-element="HeartIcon" data-sentry-source-file="hero-section.tsx" />
                <HeartIcon className="size-4 fill-red-500 text-red-500 lg:size-5" data-sentry-element="HeartIcon" data-sentry-source-file="hero-section.tsx" />
                <HeartIcon className="size-4  fill-red-500 text-red-500 lg:size-5" data-sentry-element="HeartIcon" data-sentry-source-file="hero-section.tsx" />
              </div>
              <p className="text-left text-sm font-light text-muted-foreground lg:text-base">
                5.2K+ happy customers
              </p>
            </div>
          </div>
          <p className="w-full text-muted-foreground lg:mx-0">
            Increase your social media in just minutes with real and
            high-quality followers, likes, and views at the cheapest prices.
            Perfect for TikTok, Instagram, and more-our safe services will get
            your account noticed in no time. Start growing now!
          </p>
        </div>
        {children}
      </div>
    </Container>;
};